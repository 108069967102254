import React from 'react';
import { Link } from 'react-router-dom';
import Article from "../../dto/Article";

interface ArticleCardProps {
    article: Article;
}

const ArticleMiniCard: React.FC<ArticleCardProps> = ({ article }) => {
    const { id, title, author, publishedAt, category, urlToImage } = article;

    return (
        <Link to={`/article/${id}`} className="article-card-link">
            <div className="article-card">
                <img src={urlToImage} alt={title} className="card-image" />
                <div className="card-content">
                    <h4 className="card-title">{title}</h4>
                    <div className="card-footer">
                        <img src={author.image} alt={author.name} className="card-author-image" loading='lazy'/>
                        <div className="card-author-info">
                            <span className="card-author-name">{author.name}</span>
                            <span className="card-date">{new Date(publishedAt).toLocaleDateString()}</span>
                        </div>
                        <span className="card-category">{category}</span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ArticleMiniCard;
