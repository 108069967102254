import React, {useState, useEffect} from 'react';
import './ArticleMiddleList.css';
import {Link} from 'react-router-dom';
import Article from "../dto/Article";
import ArticleMediumCard from "./ArticleMiddleCard";
import Loading from "./Loading";

const ArticleMiddleList: React.FC = () => {
    let [articles, setArticles] = useState<Article[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const fetchArticles = async (page: number) => {
        const apikeySupabase = process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY;
        try {
            console.log(`Range ${page * 5}-${(page + 1) * 5 - 1}`);
            const response = await fetch(`${process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL}/rest/v1/article_details`, {
                method: 'GET',
                headers: {
                    'apikey': apikeySupabase,
                    'Authorization': `Bearer ${apikeySupabase}`,
                    'Content-Type': 'application/json',
                    'Range': `${page * 5}-${(page + 1) * 5 - 1}`
                } as HeadersInit
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.length > 0) {
                setArticles(prevArticles => [...prevArticles, ...data]);
                setHasMore(data.length === 5); // Assuming the API returns up to 5 articles per request
            } else {
                setHasMore(false);
                setError('No articles found');
            }
        } catch (error) {
            setError('Error fetching articles');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchArticles(page);
    }, [page]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading || !hasMore) return;
            setPage(prevPage => prevPage + 1);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    useEffect(() => {
        setLoading(true);
        fetchArticles(0);
    }, []);

    if (loading) {
        return <Loading/>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (articles.length < 4) {
        return <p>Not enough articles to display</p>;
    }

    return (
        <div className="article-middle-block">
            <div className="article-middle-leftside">
                <div className="main-middle-article">
                    <Link to={`/article/${articles[0].id}`} className="main-middle-article-link">
                        <img src={articles[0].urlToImage} alt={articles[0].title}
                             className="main-middle-article-image"/>
                        <div className="main-middle-article-content">
                            <span className="main-middle-article-category">{articles[0].category}</span>
                            <h3 className="main-middle-article-title">{articles[0].title}</h3>
                            <div className="main-middle-article-footer">
                                <span className="main-middle-article-author">{articles[0].author.name}</span>
                                <span
                                    className="main-middle-article-date">{new Date(articles[0].publishedAt).toLocaleDateString()}</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="side-middle-articles">
                    {articles.map((article, index) => (
                        <ArticleMediumCard key={index} article={article}/>
                    ))}
                </div>
            </div>
            <div className="article-middle-rightside">
            </div>
        </div>
    );
};

export default ArticleMiddleList;