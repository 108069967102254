import React from 'react';
import { Link } from 'react-router-dom';
import './ArticleMiddleCard.css';
import Article from "../dto/Article";

interface ArticleCardProps {
    article: Article;
}

const ArticleMiddleCard: React.FC<ArticleCardProps> = ({ article }) => {
    const { id, title, author, publishedAt, category, urlToImage } = article;

    return (
        <Link to={`/article/${id}`} className="middle-card-link">
            <div className="middle-card">
                <img src={urlToImage} alt={title} className="middle-card-image" />
                <div className="middle-card-content">
                    <h4 className="middle-card-title">{title}</h4>
                    <div className="middle-card-footer">
                        <img src={author.image} alt={author.name} className="middle-card-author-image" loading='lazy'/>
                        <div className="middle-card-author-info">
                            <span className="middle-card-author-name">{author.name}</span>
                            <span className="middle-card-date">{new Date(publishedAt).toLocaleDateString()}</span>
                        </div>
                        <span className="middle-card-category">{category}</span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ArticleMiddleCard;