import React, { useState, useEffect } from 'react';
import './ArticleList.css';
import { Link } from 'react-router-dom';
import Article from "../../dto/Article";
import Loading from "../Loading";
import ArticleMiniCard from "./ArticleMiniCard";

const ArticleList: React.FC = () => {
    const [articles, setArticles] = useState<Article[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const apikeySupabase = process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY;
        const fetchArticles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL}/rest/v1/random_article_details`, {
                    method: 'GET',
                    headers: {
                        'apikey': apikeySupabase,
                        'Authorization': `Bearer ${apikeySupabase}`,
                        'Content-Type': 'application/json'
                    } as HeadersInit
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data) {
                    setArticles(data);
                } else {
                    setError('No articles found');
                }
            } catch (error) {
                setError('Error fetching articles');
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (articles.length < 4) {
        return <p>Not enough articles to display</p>;
    }

    return (
        <div className="article-list">
            <div className="main-article">
                <Link to={`/article/${articles[0].id}`} className="main-article-link">
                    <img src={articles[0].urlToImage} alt={articles[0].title} className="main-article-image" />
                    <div className="main-article-content">
                        <span className="main-article-category">{articles[0].category}</span>
                        <h3 className="main-article-title">{articles[0].title}</h3>
                        <div className="main-article-footer">
                            <span className="main-article-author">{articles[0].author.name}</span>
                            <span className="main-article-date">{new Date(articles[0].publishedAt).toLocaleDateString()}</span>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="side-articles">
                {articles.slice(1, 5).map((article, index) => (
                    <ArticleMiniCard key={index} article={article} />
                ))}
            </div>
        </div>
    );
};

export default ArticleList;
